import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, increment } from 'firebase/firestore';
import Map from '../components/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton, EmailShareButton, } from 'react-share';
import { FacebookIcon, TwitterIcon, LinkedinIcon, WhatsappIcon, EmailIcon } from 'react-share';
import CoinImage from '../assets/coin_blank.png';
import { Helmet } from 'react-helmet-async';
import GoogleSignInButton from '../components/GoogleSignInButton';
import { AuthContext } from '../context/AuthContext';
import SponsorForm from '../components/SponsorForm';
import Banner from '../assets/wynwoodaerial.png';
import { formatDate, formatTime } from '../utils/dateUtils';  
const EventDetails = () => {
  const {isAuthenticated, user} = useContext(AuthContext);
  const shareUrl = window.location.href;  // Current page URL for sharing
  const { eventId } = useParams();  // Get the event ID from the URL
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rsvpStatus, setRsvpStatus] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false); // Check-in state
  const [showSignup, setShowSignup] = useState(false); // Show or hide sign-up form
  const [isSponsored, setIsSponsored] = useState(false); // Track sponsorship
  const [message, setMessage] = useState('');

  const formatEventDate = (event) => {
    if (!event.date) return '';
    
    const startDate = event.date.toDate ? event.date.toDate() : new Date(event.date);
    return startDate.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const formatTime = (timeStr) => {
    if (!timeStr) {
      console.log('No time string provided');
      return '';
    }
    
    try {
      console.log('Formatting time:', timeStr); // Debug log
      
      // Handle Firestore Timestamp
      if (timeStr.seconds && timeStr.nanoseconds) {
        const date = new Date(timeStr.seconds * 1000);
        return date.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });
      }
      
      // If it's already in 12-hour format (e.g., "7:00 PM")
      if (typeof timeStr === 'string' && timeStr.includes('M')) {
        return timeStr;
      }
      
      // If it's in 24-hour format (HH:mm)
      if (typeof timeStr === 'string' && timeStr.includes(':')) {
        const [hours, minutes] = timeStr.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const hour12 = hours % 12 || 12;
        return `${hour12}:${minutes.toString().padStart(2, '0')} ${period}`;
      }

      // If it's a Date object
      if (timeStr instanceof Date) {
        return timeStr.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: '2-digit',
          hour12: true
        });
      }

      console.log('Unhandled time format:', timeStr);
      return '';
    } catch (error) {
      console.error('Error formatting time:', error, timeStr);
      return '';
    }
  };

  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventRef = doc(db, 'events', eventId);
        const eventSnapshot = await getDoc(eventRef);
  
        if (eventSnapshot.exists()) {
          const eventData = eventSnapshot.data();
          console.log('Raw event data:', eventData); // Debug log
          console.log('Start time:', eventData.startTime); // Debug log
          setEvent(eventData);
          setIsSponsored(!!eventData.sponsoredBy); 
          setRsvpStatus(eventData.rsvps && user && eventData.rsvps.includes(user?.email));
  
        } else {
          setError('Event not found');
        }
      } catch (err) {
        console.error('Error fetching event:', err);
        setError('Failed to fetch event details: ' + err.message);
      } finally {
        setLoading(false);
      }
    };
  
    fetchEvent();
  }, [eventId, user]);

  const handleSponsorEvent = async () => {
    const user = auth.currentUser;
    if (!user) {
      setError('You need to be logged in to sponsor an event.');
      return;
    }
  
    try {
      const businessDoc = await getDoc(doc(db, 'businesses', user.uid));
      if (!businessDoc.exists()) {
        setError('Business profile not found.');
        return;
      }
  
      const businessData = businessDoc.data();
      const eventRef = doc(db, 'events', eventId);
  
      // Use arrayUnion to add the sponsor object without overwriting existing sponsors
      await updateDoc(eventRef, {
        sponsoredBy: arrayUnion({
          sponsorId: user.uid,
          sponsorName: businessData.businessName,
        }),
      });
  
      setIsSponsored(true);
      setMessage('You have successfully sponsored this event!');
    } catch (error) {
      setError(`Failed to sponsor event: ${error.message}`);
    }
  };

  const handleRsvp = async () => {
    const user = auth.currentUser;

    if (!user) {
      setShowSignup(true);  // Show signup form if user is not authenticated
      return;
    }

    try {
      const eventRef = doc(db, 'events', eventId);

      // Update the event document by adding the user's email to the RSVPs array
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      setRsvpStatus(true);
      setMessage(`You have successfully RSVP\'d to this event! You will receive your ${event.coins} NeighborhoodCoins when you Check in to the event.`); // Add success message
    } catch (err) {
      setError('Failed to RSVP: ' + err.message);
    }
  };

  const handleCheckIn = async () => {
    if (!user) {
      setShowSignup(true);  // Show sign-up form if user is not authenticated
      return;
    }
  
    try {
      // Create a unique check-in ID for this event and user
      const checkInRef = doc(db, 'check-ins', `${eventId}_${user.uid}`);
      
      // Store the check-in details in Firestore
      await setDoc(checkInRef, {
        eventId: eventId,
        userId: user.uid,
        checkInTime: new Date(), // Store the current time
      });
  
      const eventRef = doc(db, 'events', eventId);
      const userRef = doc(db, 'users', user.uid);
  
      // Mark the user as checked in by adding their email to the event's `checkIns` array
      await updateDoc(eventRef, {
        checkIns: arrayUnion(user.email),  // Add user email to check-ins
      });
  
      // Add NeighborhoodCoins to the user's balance
      await updateDoc(userRef, {
        neighborCoinBalance: increment(event.coins || 0), // Increment balance by event's coin value
      });
  
      setCheckedIn(true); // Update state to reflect successful check-in
      setMessage(`You have successfully checked in and earned ${event.coins || 0} NeighborhoodCoins!`);
    } catch (err) {
      setError('Failed to check in: ' + err.message);
    }
  };

  const isEventActive = (eventDate, eventStartTime, eventEndTime) => {
    if (!eventDate || !eventStartTime || !eventEndTime) return false;
    
    try {
      const currentTime = new Date();
      const eventDateObj = eventDate instanceof Date ? eventDate : eventDate.toDate();
      
      // Create start and end date objects
      const startDateTime = new Date(eventDateObj);
      const endDateTime = new Date(eventDateObj);
      
      // Handle time strings
      if (typeof eventStartTime === 'string') {
        const [startHours, startMinutes] = eventStartTime.split(':').map(Number);
        startDateTime.setHours(startHours, startMinutes);
      }
      
      if (typeof eventEndTime === 'string') {
        const [endHours, endMinutes] = eventEndTime.split(':').map(Number);
        endDateTime.setHours(endHours, endMinutes);
      }

      return currentTime >= startDateTime && currentTime <= endDateTime;
    } catch (error) {
      console.error('Error checking event active status:', error);
      return false;
    }
  };
  const closeModal = () => {
    setShowSignup(false);
  };

  if (loading) return <p>Loading event details...</p>;
  if (error) return <p>{error}</p>;

  const currentDate = new Date().toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'America/New_York'
  });

  const eventDateString = event && new Date(event.date).toLocaleDateString('en-US', {
    weekday:'long',
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    timeZone: 'America/New_York'
  });
  const normalizedNeighborhood = (neighborhood) => {
    return neighborhood.toLowerCase().replace(/\s+/g, '');
  }
  const handleSponsorClick = () => {
    navigate(`/sponsor?eventId=${eventId}`);
  };
  return (
    <div className="min-h-screen bg-neutralLight">
      {/* Enhanced Hero Section with Gradient */}
      <div className="relative py-32 mb-12">
        <div 
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: `linear-gradient(to right, rgba(0, 128, 128, 0.9), rgba(0, 128, 128, 0.7)), url(${Banner})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <div className="container mx-auto px-4 relative z-10">
          <h1 className="text-5xl font-bold text-white text-center mb-4">{event.name}</h1>
          <div className="text-xl text-white text-center opacity-90">
            {formatEventDate(event)}
            <div className="mt-2 space-y-1">
              {event.startTime && (
                <div className="text-lg font-bold">
                  Event starts: {formatTime(event.startTime)}
                </div>
              )}
              {event.endTime && (
                <div className="text-lg">
                  Ends: {formatTime(event.endTime)}
                </div>
              )}
              {event.doorsOpenTime && (
                <div className="text-base text-white/80">
                  Doors open: {formatTime(event.doorsOpenTime)}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content with Sticky Sidebar */}
      <div className="container mx-auto px-4 py-8"> 
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Main Content Area */}
          <div className="lg:col-span-2">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-8">
              {/* Event Image */}
              <div className="w-full h-[500px] bg-gray-100">
                <img 
                  src={event.imageUrl} 
                  alt={event.name} 
                  className="w-full h-full object-contain"
                />
              </div>
              
              {/* Event Description */}
              <div className="p-8">
                <div className="prose max-w-none">
                  <h2 className="text-2xl font-bold text-teal mb-4">About This Event</h2>
                  <div className="text-gray-600 mb-8">{event.description}</div>
                </div>
              </div>
            </div>

            {/* Map Section */}
            <div className="bg-white rounded-lg shadow-lg overflow-hidden p-6">
              <h2 className="text-2xl font-bold text-teal mb-4">Location</h2>
              <Map event={event} />
            </div>
          </div>

          {/* Sticky Sidebar */}
          <div className="lg:col-span-1">
            <div className="sticky top-4">
              <div className="bg-white rounded-lg shadow-lg p-6 mb-4">
                {/* Event Details Card */}
                <div className="space-y-4">
                  {/* Location Info */}
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h3 className="font-semibold text-teal mb-2">Where</h3>
                    <div className="space-y-1">
                      <div className="text-mustard font-bold">{event.neighborhood}</div>
                      <div className="font-medium">{event.venue}</div>
                      <div className="text-gray-600">{event.location}</div>
                    </div>
                  </div>

                  {/* Time Info */}
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h3 className="font-semibold text-teal mb-2">When</h3>
                    <div className="space-y-1">
                      <div>{event.startTime && `Starts: ${formatTime(event.startTime)}`}</div>
                      <div>{event.endTime && `Ends: ${formatTime(event.endTime)}`}</div>
                      <div>{event.doorsOpenTime && `Doors: ${formatTime(event.doorsOpenTime)}`}</div>
                    </div>
                  </div>

                  {/* Coins/Rewards */}
                  <div className="flex items-center justify-between p-4 bg-teal bg-opacity-10 rounded-lg">
                    <div className="flex items-center">
                      <img src={CoinImage} alt="coins" className="w-8 h-8 mr-2" />
                      <span className="font-bold text-teal text-xl">+{event.coins}</span>
                    </div>
                    {!rsvpStatus ? (
                      <button 
                        onClick={handleRsvp}
                        className="bg-mustard text-white px-4 py-2 rounded-lg hover:bg-teal transition-colors"
                      >
                        Earn Coins
                      </button>
                    ) : (
                      <button
                        onClick={handleCheckIn}
                        disabled={!isEventActive(event.date, event.startTime, event.endTime)}
                        className={`px-4 py-2 rounded-lg ${
                          isEventActive(event.date, event.startTime, event.endTime)
                            ? 'bg-mustard text-white hover:bg-teal'
                            : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                        }`}
                      >
                        {checkedIn ? 'Checked In' : 'Check In'}
                      </button>
                    )}
                  </div>

                  {/* Ticket Button */}
                  {event.ticketUrl && (
                    <a
                      href={event.ticketUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block w-full bg-mustard text-white text-center py-4 px-6 rounded-lg hover:bg-teal transition-colors font-bold"
                    >
                      Get Tickets
                    </a>
                  )}
                </div>
              </div>

              {/* Share Section */}
              <div className="bg-white rounded-lg shadow-lg p-6">
                <h2 className="font-semibold text-teal mb-4">Share Event</h2>
                <div className="flex justify-center space-x-4">
                  <FacebookShareButton url={shareUrl}>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <EmailShareButton url={shareUrl}>
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Sign Up Modal */}
      {showSignup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 max-w-md w-full mx-4">
            <h3 className="text-2xl font-bold text-teal mb-6">Please Sign Up to RSVP</h3>
            <GoogleSignInButton />
            <button
              onClick={() => setShowSignup(false)}
              className="mt-4 w-full bg-gray-200 text-gray-700 py-2 rounded-lg hover:bg-gray-300 transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventDetails;