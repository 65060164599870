import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import useAuthState from '../hooks/useAuthState';
import WelcomeMessage from '../components/WelcomeMessage';
import GoogleSignIn from '../components/GoogleSignInButton';
import RewardCountdown from '../components/RewardCountdown';
import coins from '../assets/coins.png';
import groupWomen from '../assets/group_women.png';
import handPhone from '../assets/hand_phone.png';
import customer from '../assets/customer.png';
import logo from '../assets/NeigbhorCoinLogo.png';
// import heroBackground from '../assets/hero-background.webp'; // Optional hero background image

function Home() {
  const isAuthenticated = useAuthState(auth);
  const user = auth.currentUser;
  const userEmail = user ? user.email : '';
  const userRole = user ? user.role : 'attendee';
  const [showPopup, setShowPopup] = useState(false);
  const [showReward, setShowReward] = useState(false);

  const roleDisplayNames = {
    businessOwner: "Business Venue Owner",
    eventPlanner: "Event Planner",
    attendee: "Attendee"
  };

  const getDashboardLink = () => {
    if (userRole === 'businessOwner') return '/business-dashboard';
    if (userRole === 'eventPlanner') return '/event-admin';
    return '/dashboard';
  };
// Show popup after a short delay when the page loads
useEffect(() => {
  // Check if user has already seen the popup in this session
  const hasSeenPopup = sessionStorage.getItem('hasSeenRewardPopup');
  
  if (!hasSeenPopup) {
    const timer = setTimeout(() => {
      setShowPopup(true);
      setShowReward(true);
    }, 3000); // Show popup after 3 seconds
    
    return () => clearTimeout(timer);
  }
}, []);

const handleClosePopup = () => {
  setShowPopup(false);
  // Set session storage to prevent showing popup again in this session
  sessionStorage.setItem('hasSeenRewardPopup', 'true');
};

const handleCloseReward = () => {
  setShowReward(false);
  // Set session storage to prevent showing popup again in this session
  sessionStorage.setItem('hasSeenRewardPopup', 'true');
};

  return (
    <div className="min-h-screen bg-gray-50 text-gray-800 flex flex-col font-sans">
      {/* Show RewardCountdown when showReward is true */}
      {showReward && <RewardCountdown onClose={handleCloseReward} />}
      
      {/* HERO SECTION */}
      <section
        className="relative w-full h-[60vh] min-h-[400px] bg-center bg-cover flex items-center justify-center"
        style={{ backgroundImage: `url(${logo})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative text-center text-white px-4">
          <h1 className="text-3xl sm:text-4xl font-bold mb-4">
            NeighborhoodCoins Rewards!
          </h1>
          <p className="text-lg mb-6">
            Earn rewards &amp; support local businesses in your community.
          </p>
          {!isAuthenticated && (
            <Link
              to="/signup"
              className="inline-block bg-coral text-white font-bold py-3 px-8 rounded-lg hover:bg-teal transform hover:scale-105 transition-all duration-200 shadow-md text-lg"
            >
              Login / Sign Up
            </Link>
          )}
          {isAuthenticated && (
            <WelcomeMessage
              auth={auth}
              userEmail={userEmail}
              userRole={userRole}
              roleDisplayNames={roleDisplayNames}
              getDashboardLink={getDashboardLink}
            />
          )}
        </div>
      </section>

      <main className="flex-grow w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* HOW IT WORKS SECTION */}
        <section className="mb-20 px-4 sm:px-0">
          <h2 className="text-3xl font-bold text-center mb-12">How It Works</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mx-auto max-w-full overflow-hidden">
            {/* Step 1 */}
            <div className="flex flex-col items-center text-center p-6 sm:p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 w-full">
              <img src={coins} alt="Sign Up" className="w-20 h-20 mb-6" />
              <h3 className="text-xl font-semibold mb-4">1. Sign Up</h3>
              <p className="text-base mb-6">
                Create your account and join our vibrant community.
              </p>
              <Link
                to="/signup"
                className="mt-auto inline-block bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-lg transition-colors duration-200"
              >
                Get Started
              </Link>
            </div>

            {/* Step 2 */}
            <div className="flex flex-col items-center text-center p-6 sm:p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 w-full">
              <img src={handPhone} alt="Create Rewards" className="w-20 h-20 mb-6" />
              <h3 className="text-xl font-semibold mb-4">2. Create Rewards</h3>
              <p className="text-base mb-6">
                Design rewards your customers will love.
              </p>
            </div>

            {/* Step 3 */}
            <div className="flex flex-col items-center text-center p-6 sm:p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 w-full">
              <img src={groupWomen} alt="Engage Customers" className="w-20 h-20 mb-6" />
              <h3 className="text-xl font-semibold mb-4">3. Engage Customers</h3>
              <p className="text-base mb-6">
                Launch your loyalty program and connect with locals.
              </p>
            </div>

            {/* Step 4 */}
            <div className="flex flex-col items-center text-center p-6 sm:p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 w-full">
              <img src={customer} alt="Grow Business" className="w-20 h-20 mb-6" />
              <h3 className="text-xl font-semibold mb-4">4. Grow Business</h3>
              <p className="text-base mb-6">
                Gain insights and support to expand your reach.
              </p>
              <Link
                to="/contact"
                className="mt-auto inline-block bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-lg transition-colors duration-200"
              >
                Free Consultation
              </Link>
            </div>
          </div>
        </section>

        {/* BUSINESS & EVENT PLANNER CTA */}
        <section className="mb-20 bg-tealLight py-16 px-8 rounded-xl text-center shadow-xl border border-teal">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl md:text-4xl font-bold mb-6 text-teal">
              Are You a Local Business or Event Planner?
            </h2>
            <p className="text-lg md:text-xl mb-8 text-gray">
              Get 2 months free when you list your business or events today. Connect with
              engaged local customers.
            </p>
            <Link
              to="/signup"
              className="inline-block bg-teal text-white font-bold py-4 px-8 rounded-lg hover:bg-coral transform hover:scale-105 transition-all duration-200 shadow-md"
            >
              Sign Up Today
            </Link>
          </div>
        </section>

        {/* OUR SERVICES SECTION */}
        <section className="mb-20">
          <h2 className="text-3xl font-bold text-center mb-12">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Basic Package */}
            <div className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
              <h3 className="text-xl font-semibold mb-4">Basic Package</h3>
              <p className="text-base mb-6">
                Ideal for small businesses new to loyalty programs.
              </p>
              <p className="text-lg font-bold mb-6">$29/month</p>
              <ul className="list-disc list-inside text-left mb-6 text-base">
                <li>Feature in Our Directory</li>
                <li>Basic Analytics</li>
                <li>Email Support</li>
              </ul>
              <Link
                to="/signup"
                className="inline-block bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-lg transition-colors duration-200"
              >
                Get Started
              </Link>
            </div>
            {/* Standard Package */}
            <div className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
              <h3 className="text-xl font-semibold mb-4">Standard Package</h3>
              <p className="text-base mb-6">
                Perfect for businesses ready to grow their customer base.
              </p>
              <p className="text-lg font-bold mb-6">$59/month</p>
              <ul className="list-disc list-inside text-left mb-6 text-base">
                <li>All Basic Features + Advanced Analytics</li>
                <li>Custom Rewards</li>
                <li>Priority Email Support</li>
              </ul>
              <Link
                to="/signup"
                className="inline-block bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-lg transition-colors duration-200"
              >
                Get Started
              </Link>
            </div>
            {/* Premium Package */}
            <div className="p-8 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 text-center">
              <h3 className="text-xl font-semibold mb-4">Premium Package</h3>
              <p className="text-base mb-6">
                For established businesses seeking premium support and marketing.
              </p>
              <p className="text-lg font-bold mb-6">$99/month</p>
              <ul className="list-disc list-inside text-left mb-6 text-base">
                <li>Dedicated Account Manager</li>
                <li>24/7 Support</li>
                <li>Exclusive Promotions</li>
              </ul>
              <Link
                to="/signup"
                className="inline-block bg-green-600 hover:bg-green-700 text-white py-3 px-6 rounded-lg transition-colors duration-200"
              >
                Get Started
              </Link>
            </div>
          </div>
        </section>

        {/* FEEDBACK SECTION */}
        <section className="mb-12 text-center">
          <p className="text-gray-700">
            We’d love to hear from you!{' '}
            <a
              href="https://forms.gle/yJbTcohiQXQRpsRU7"
              target="_blank"
              rel="noopener noreferrer"
              className="text-green-600 font-bold hover:text-green-700"
            >
              Give Feedback
            </a>
          </p>
        </section>
      </main>
    </div>
  );
}

export default Home;
