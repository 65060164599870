import React, { useState, useEffect } from 'react';

const RewardCountdown = ({ onClose }) => {
  // Set a specific future date for the event
  const eventDate = new Date('2024-03-15T18:00:00'); // Update this to your desired event date
  
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [reward, setReward] = useState(null);
  const [isVisible, setIsVisible] = useState(true);
  
  // Calculate time remaining
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const difference = eventDate - now;
      
      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 * 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        });
      } else {
        clearInterval(timer);
      }
    }, 1000);
    
    return () => clearInterval(timer);
  }, []);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    setIsSpinning(true);
    
    // Simulate wheel spinning and reward selection
    setTimeout(() => {
      const rewards = [
        '500 NeighborhoodCoins',
        '20% Discount',
        '1000 NeighborhoodCoins',
        'Free Premium Week',
        '300 NeighborhoodCoins',
        'Mystery Box'
      ];
      const randomReward = rewards[Math.floor(Math.random() * rewards.length)];
      setReward(randomReward);
      setIsSpinning(false);
    }, 3000);
  };

  if (!isVisible) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-2xl w-full max-w-md relative overflow-hidden">
        {/* Close button */}
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 z-10"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        
        {/* Content */}
        <div className="bg-gradient-to-r from-teal to-coral p-6 text-white">
          <h2 className="text-2xl font-bold text-center mb-4">Exclusive Reward Event!</h2>
          
          {!isSubmitted ? (
            <>
              <div className="text-center mb-6">
                <p className="text-lg font-semibold mb-2">Time Until Reward Event:</p>
                <div className="flex justify-center space-x-4">
                  <div className="bg-white bg-opacity-20 p-2 rounded-lg">
                    <span className="text-2xl font-bold">{timeLeft.days}</span>
                    <p className="text-xs">Days</p>
                  </div>
                  <div className="bg-white bg-opacity-20 p-2 rounded-lg">
                    <span className="text-2xl font-bold">{timeLeft.hours}</span>
                    <p className="text-xs">Hours</p>
                  </div>
                  <div className="bg-white bg-opacity-20 p-2 rounded-lg">
                    <span className="text-2xl font-bold">{timeLeft.minutes}</span>
                    <p className="text-xs">Min</p>
                  </div>
                  <div className="bg-white bg-opacity-20 p-2 rounded-lg">
                    <span className="text-2xl font-bold">{timeLeft.seconds}</span>
                    <p className="text-xs">Sec</p>
                  </div>
                </div>
              </div>
              
              <div className="bg-white bg-opacity-10 p-4 rounded-lg mb-6">
                <p className="text-center mb-4">Sign up now to secure your chance to win exclusive rewards at our upcoming event!</p>
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <input 
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      required
                      className="w-full p-3 rounded-lg text-gray-800 focus:ring-2 focus:ring-yellow-400 outline-none"
                    />
                  </div>
                  <button 
                    type="submit"
                    className="w-full bg-yellow-400 hover:bg-yellow-500 text-gray-800 font-bold py-3 px-4 rounded-lg transition-colors duration-200"
                  >
                    Reserve My Reward Spin!
                  </button>
                </form>
              </div>
            </>
          ) : (
            <div className="text-center">
              {isSpinning ? (
                <>
                  <div className="relative w-48 h-48 mx-auto mb-6">
                    <div className="absolute inset-0 rounded-full border-8 border-dashed border-yellow-400 animate-spin"></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <div className="bg-white bg-opacity-30 p-4 rounded-full">
                        <p className="text-lg font-bold">Spinning...</p>
                      </div>
                    </div>
                  </div>
                  <p className="text-xl font-semibold">Finding your reward...</p>
                </>
              ) : (
                <>
                  <div className="bg-yellow-400 text-gray-800 p-6 rounded-lg mb-6">
                    <p className="text-xl font-bold mb-2">Congratulations!</p>
                    <p className="text-2xl font-bold mb-4">You've reserved:</p>
                    <div className="text-3xl font-extrabold mb-4">{reward}</div>
                    <p>We'll email you details about how to claim your reward during our event!</p>
                  </div>
                  <p className="text-lg">
                    Event starts in: {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
                  </p>
                </>
              )}
            </div>
          )}
          
          <div className="mt-6 text-center text-xs text-white text-opacity-70">
            <p>* By signing up, you agree to NeighborhoodCoin's terms and conditions</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardCountdown;