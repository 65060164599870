import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { db, auth } from '../firebase';
import { collection, getDocs, doc, updateDoc, arrayUnion } from 'firebase/firestore';
import Map from '../components/Map';
import { Link, useNavigate } from 'react-router-dom';
import CoinImage from '../assets/coin_blank.png';
import { Timestamp } from "firebase/firestore";
import Banner from '../assets/wynwoodaerial.png';
import './Events.css';
import GoogleSignInButton from '../components/GoogleSignInButton';
import axios from 'axios';
import { formatDate, formatTime } from '../utils/dateUtils';  // Add this import
import MailchimpSubscribe from '../components/MailchimpSubscribe'; // Re-add import

const Events = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [externalEvents, setExternalEvents] = useState([]);
  const [error, setError] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [showSignup, setShowSignup] = useState(false);
  const [rsvpMessages, setRsvpMessages] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date());
  

  const handleIconClick = (eventId) => {
    setSelectedEventId(selectedEventId === eventId ? null : eventId);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, 'events');
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsList = eventsSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            date: data.date instanceof Timestamp ? data.date.toDate() : new Date(data.date),
            startTime: data.startTime || '00:00',
            endTime: data.endTime || '23:59'
          };
        });
        setEvents(eventsList);
      } catch (err) {
        setError('Failed to fetch events: ' + err.message);
      }
    };

    fetchEvents();
  }, []);

 
  
  const normalizedNeighborhood = (neighborhood) => {
    return neighborhood.toLowerCase().replace(/\s+/g, '');
  };

  const parseTime = (timeString) => {
    if (!timeString) return { hours: 0, minutes: 0 };
    
    try {
      if (timeString instanceof Date) {
        return {
          hours: timeString.getHours(),
          minutes: timeString.getMinutes()
        };
      }

      // Handle string format "HH:mm"
      if (typeof timeString === 'string') {
        const [hours, minutes] = timeString.split(':').map(Number);
        return {
          hours: isNaN(hours) ? 0 : hours,
          minutes: isNaN(minutes) ? 0 : minutes
        };
      }

      return { hours: 0, minutes: 0 };
    } catch (error) {
      console.error('Error parsing time:', error);
      return { hours: 0, minutes: 0 };
    }
  };

  const categorizeEvents = (events) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - today.getDay()));

    const categorized = {
      today: [],
      thisWeek: [],
      upcoming: [],
    };

    events.forEach(event => {
      try {
        // Handle both Timestamp and regular Date objects
        const eventDate = event.date instanceof Date ? event.date : event.date.toDate();
        
        // Create event start and end times
        const startTime = parseTime(event.startTime);
        const endTime = parseTime(event.endTime);
        
        const eventStartDateTime = new Date(eventDate);
        eventStartDateTime.setHours(startTime.hours, startTime.minutes);
        
        const eventEndDateTime = new Date(eventDate);
        eventEndDateTime.setHours(endTime.hours || 23, endTime.minutes || 59);

        // Only filter out events that have completely ended
        if (eventEndDateTime >= now) {
          const eventDateOnly = new Date(eventDate.getFullYear(), eventDate.getMonth(), eventDate.getDate());
          
          if (eventDateOnly.getTime() === today.getTime()) {
            categorized.today.push(event);
          } else if (eventDateOnly > today && eventDateOnly <= endOfWeek) {
            categorized.thisWeek.push(event);
          } else if (eventDateOnly > endOfWeek) {
            categorized.upcoming.push(event);
          }
        }
      } catch (error) {
        console.error('Error categorizing event:', error, event);
      }
    });

    return categorized;
  };

  const handleRsvp = async (event) => {
    const user = auth.currentUser;

    if (!user) {
      setShowSignup(true);
      return;
    }

    try {
      const eventRef = doc(db, 'events', event.id);
      await updateDoc(eventRef, {
        rsvps: arrayUnion(user.email)
      });

      setRsvpMessages(prev => ({
        ...prev,
        [event.id]: `Congratulations! You have RSVP'd to ${event.name}. You have ${event.coins} NeighborhoodCoins pending for participating.`
      }));
    } catch (err) {
      setError('Failed to RSVP: ' + err.message);
    }
  };

  const isRsvpAllowed = (eventDate, eventEndTime) => {
    const eventEndDateTime = new Date(`${eventDate.toISOString().split('T')[0]}T${eventEndTime}:00`);
    return currentTime <= eventEndDateTime;
  };

  const getEventLabel = (ticketPrice) => {
    return ticketPrice && ticketPrice > 0 ? "PAID" : "FREE";
  };

  const renderEvents = (eventsList, categoryTitle) => {
    if (eventsList.length === 0) return null;

    return (
      <div className="mb-12">
        <h3 className="text-3xl font-bold text-uppercase mb-8 text-center">{categoryTitle}</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
          {eventsList.map(event => (
            <div key={event.id} className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform hover:scale-102">
              <div className="relative">
                <Link to={`/miami/event/${event.id}`}>
                  <img 
                    src={event.imageUrl} 
                    alt={event.name} 
                    className="w-full h-64 object-cover"
                  />
                  <div className="absolute top-4 right-4">
                    <div className="bg-mustard text-teal px-3 py-1 rounded-full font-bold text-sm">
                      {getEventLabel(event.ticketPrice)}
                    </div>
                    </div>
                  </Link>
                </div>

              <div className="p-6">
                <Link to={`/miami/event/${event.id}`} className="block mb-4">
                  <h4 className="text-2xl font-bold text-teal hover:text-mustard transition-colors">
                    {event.name}
                  </h4>
                </Link>

                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center text-gray-600">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-teal mr-2" />
                    <Link 
                      to={`/miami/${normalizedNeighborhood(event.neighborhood)}`}
                      className="hover:text-mustard transition-colors"
                    >
                     <div className="text-teal"> {event.neighborhood}
                      </div>
                    </Link>
                  </div>
                  <div 
                    className="coin-display flex items-center bg-teal bg-opacity-10 rounded-full px-4 py-2"
                  >
                    <img src={CoinImage} alt="coins" className="w-6 h-6 mr-2" />
                    {auth.currentUser ? (
                      <button
                        onClick={() => handleRsvp(event)}
                        className="font-bold text-teal hover:text-mustard transition-colors"
                      >
                        Earn {event.coins}
                      </button>
                    ) : (
                      <button
                        onClick={() => setShowSignup(true)}
                        className="font-bold text-teal hover:text-mustard transition-colors"
                      >
                        Earn {event.coins}
                      </button>
                    )}
                  </div>
                </div>

                <div className="text-gray-600 font-medium">
                  {new Date(event.date).toLocaleDateString('en-US', {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </div>

                {selectedEventId === event.id && (
                  <div className="mt-4 rounded-lg overflow-hidden">
                    <Map event={event} />
                  </div>
                )}

                {rsvpMessages[event.id] && (
                  <div className="mt-4 p-3 bg-teal bg-opacity-10 rounded-lg">
                    <p className="text-teal font-medium">{rsvpMessages[event.id]}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const sortEventsByDate = (events) => {
    return events.sort((a, b) => {
      const dateA = new Date(a.date instanceof Object && a.date.toDate ? a.date.toDate() : a.date);
      const dateB = new Date(b.date instanceof Object && b.date.toDate ? b.date.toDate() : b.date);
      return dateA - dateB;
    });
  };

  const categorizedEvents = categorizeEvents(events);
  const sortedTodayEvents = sortEventsByDate(categorizedEvents.today);
  const sortedThisWeekEvents = sortEventsByDate(categorizedEvents.thisWeek);
  const sortedUpcomingEvents = sortEventsByDate(categorizedEvents.upcoming);

  return (
    <div className="min-h-screen bg-neutralLight font-sans">
      <div 
        className="relative bg-teal py-24 mb-12"
        style={{
          backgroundImage: `url(${Banner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <div className="absolute inset-0 bg-teal bg-opacity-70"></div>
        <div className="container mx-auto px-4 relative">
          <h2 className="font-display text-4xl font-bold text-white text-center">
            Miami Community Events
          </h2>
        </div>
      </div>

      <div className="container mx-auto px-4 pb-16">
       
       

        <div className="mb-12 text-center">
          <Link 
            to="/art-basel-miami-events"
            className="inline-block bg-gradient-to-r from-mustard to-coral text-white font-bold py-4 px-8 rounded-full shadow-lg transform transition-transform hover:scale-105 hover:shadow-xl"
          >
            <div className="flex flex-col items-center">
              <span className="text-2xl mb-1">Art Basel Miami 2024</span>
              <span className="text-sm">December 3-8 | Miami Art Week</span>
            </div>
          </Link>
        </div>

        {/* Add disclaimer at the top of events list */}
        <div className="mb-8 bg-gray-50 p-4 rounded-lg text-center">
          <p className="text-gray-600">
            <span className="font-semibold">Event Attendees: </span>
            Click "Earn Coins" on community events listed on our page. Check in at the event to collect your coins. 
            Event tickets and RSVPs are managed separately by event organizers.
          </p>
        </div>

        {/* Re-add the MailchimpSubscribe component */}
        <div className="subscription-section mb-12">
          <MailchimpSubscribe />
        </div>

        {showSignup && (
          <div className="bg-white rounded-lg shadow-lg p-8 mb-12 text-center">
            <h3 className="text-2xl font-bold text-teal mb-6">
              Please Sign Up to Earn Coins
            </h3>
            <GoogleSignInButton />
          </div>
        )}

        {renderEvents(sortedTodayEvents, 'Today')}
        {renderEvents(sortedThisWeekEvents, 'This Week')}
        {renderEvents(sortedUpcomingEvents, 'Upcoming Events')}
      </div>
    </div>
  );
};

export default Events;